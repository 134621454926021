.play-old {
  background: #141b29;
  padding: 0.75em 1.25em;
  border-radius: 10px;
  margin-bottom: 0.75em;
  font-size: 0.85em;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.play-old span {
  font-weight: 600;
}

.play-old button {
  all: unset;
  cursor: pointer;
  padding: 0.5em 0.75em;
  background: linear-gradient(#b026ff, #921ad8) !important;
  background: -webkit-linear-gradient(
    #b026ff,
    #921ad8
  ); /* For older versions of WebKit */
  border-radius: 5px;
  letter-spacing: 1.5px;
  font-size: 1.25em !important;
  &:hover {
    background: #921ad8;
  }
}

@media screen and (max-width: 575px) {
  .play-old button {
    padding: 0.5em 1.25em !important;
    font-size: 1.35em !important;
    margin-left: 0.5em;
  }
}
