.how-to {
  background: #141b29;
  padding: 1.25em;
  border-radius: 10px;
  margin-top: 2em;
}
.how-to {
  h2 {
    margin-bottom: 0.35em;
  }
  p {
    font-size: 0.85em;
  }
}
