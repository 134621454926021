.conundrum-quest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.conundrum-quest .word-optn {
  width: 100%;
}
.conundrum-quest .clue {
  font-size: 0.85em;
  margin-bottom: 0.75em;
  border: 1px solid #b026ff;
  border-radius: 5px;
  width: 100%;
  padding: 0.75em;
  position: relative;
}
.conundrum-quest .game-complete {
  margin-top: 1.5em;
  padding: 0.75em 1.25em;
  border-radius: 10px;
  font-size: 0.85em;
  background: #141b29;
}
.conundrum-quest .clue::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, #af26ff1c, rgba(255, 124, 109, 0));
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  pointer-events: none;
}
.conundrum-quest .clue span {
  font-weight: 500;
}
.conundrum-quest .word-optn label span {
  display: inline-block;
  margin-top: 0.5em;
  font-weight: 300;
  letter-spacing: 1.5px;
  font-size: 0.85em;
}
.conundrum-quest .word-optn .input-ctr {
  background: #141b29;
  border: 1px solid white;
  border-radius: 3.5px;
  position: relative;
}
.conundrum-quest .word-optn input {
  all: unset;
  width: 100%;
  background: transparent;
  cursor: text;
  padding: 0.35em 0.5em;
}
.conundrum-quest .word-optn .input-ctr.incorrect {
  border-color: #a30000;
}
.conundrum-quest .word-optn .input-ctr.incorrect::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, #a3000013, rgba(255, 124, 109, 0));
  pointer-events: none;
}
.conundrum-quest .word-optn .input-ctr.correct {
  border-color: #00a300;
}
.conundrum-quest .word-optn .input-ctr.correct::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(to top, #00a30013, rgba(255, 124, 109, 0));
  pointer-events: none;
}
.conundrum-quest .check-btn {
  all: unset;
  margin-top: 1.5em;
  cursor: pointer;
  padding: 0.5em 1em;
  background: linear-gradient(#b026ff, #921ad8);
  border-radius: 5px;
  letter-spacing: 1.5px;
  font-size: 0.85em;
}
.conundrum-quest .check-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.conundrum-quest .check-btn:hover {
  background: #921ad8;
}
.conundrum-quest .old-game-btn {
  all: unset;
  margin-top: 1em;
  cursor: pointer;
  padding: 0.5em 1em;
  background: transparent;
  border-radius: 5px;
  letter-spacing: 1.5px;
  font-size: 0.85em;
  color: #921ad8;
  border: 1px solid #921ad8;
}
.conundrum-quest .old-game-btn:hover {
  background: #921ad811;
}
