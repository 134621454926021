.faq-container {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 0.25em;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid white;
}

.faq-question {
  width: 100%;
  padding: 0.5em 0;
  color: #ffffff;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  outline: none;
  font-family: "Kanit", sans-serif;
  font-size: 1em;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  padding-bottom: 10px 0;
  border-radius: 5px;
  font-size: 0.85em;
}

.faq-answer p {
  margin: 10px 0;
}

.faq-answer.active {
  max-height: 200px;
}
