.countdown {
  margin: 0.5em auto 0.25em 0;
  font-size: 0.7em;
  font-weight: 200;
  opacity: 0.55;
  letter-spacing: 1px;
  .bold {
    font-weight: 500;
  }
}
