.cookie-banner {
  position: fixed;
  bottom: 2em;
  left: 2em;
  border-radius: 5px;
  right: 0;
  background-color: #141b29;
  color: #fff;
  font-size: 0.8em !important;
  padding: 1em;
  z-index: 1000;
  width: 300px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.037), 0 2px 4px rgba(0, 0, 0, 0.037),
    0 4px 8px rgba(0, 0, 0, 0.037), 0 8px 16px rgba(0, 0, 0, 0.037),
    0 16px 32px rgba(0, 0, 0, 0.037), 0 32px 64px rgba(0, 0, 0, 0.037);
}

.cookie-banner p {
  font-size: 0.95em !important;
}

.cookie-banner a {
  color: #b026ff !important; /* Use !important to override any other styles */
  text-decoration: none; /* Ensure links have no underlines */
  font-size: 0.95em !important;
}

.cookie-banner button {
  all: unset;
  cursor: pointer;
  padding: 0.25em 0.5em;
  background: linear-gradient(#b026ff, #921ad8) !important;
  background: -webkit-linear-gradient(
    #b026ff,
    #921ad8
  ); /* For older versions of WebKit */
  border-radius: 5px;
  letter-spacing: 1.5px;
  font-size: 0.85em;
  color: white; /* Ensure the button text color is white */
  text-align: center; /* Center the button text */
}

.cookie-banner button:hover {
  background: #921ad8;
}

.cookie-banner .cookie-banner-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .cookie-banner {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.25em;
  }
}
