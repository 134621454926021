.layout-ctr {
  max-width: 500px;
  padding: 2.5em 0;
  margin: auto;
}

@media screen and (max-width: 575px) {
  .layout-ctr {
    max-width: 90%;
    padding: 1.5em 0;
  }
}
