.legal-ctr {
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.legal-ctr .privacy-policy,
.legal-ctr .cookie-policy {
  text-decoration: none;
  font-size: 0.85em;
  text-align: center;
  margin: 0 1em;
}
.legal-ctr .privacy-policy a,
.legal-ctr .cookie-policy a {
  color: #b026ff !important;
  text-decoration: none;
}
