body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: #101520;
  /* #7D8BAE */
  /* #E5857B */
  color: white;
  font-family: "Kanit", sans-serif;
}

p {
  font-weight: 300;
}
